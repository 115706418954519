import React from "react";
import { graphql } from 'gatsby';
import Layout from "../components/Layout"
import SEO from "../components/seo";
import Content from '../views/Blog/Article';
import { strapiBlogpostUrl } from './constants';

const getPost = id => fetch(strapiBlogpostUrl + '/' + id, {
  method: 'GET',
  header: {
    'Content-Type': 'application/json'
  }
}).then(res => res.json())

class Blogpost extends React.Component {
  constructor() {
    super();
    this.state = {
      post: null
    }
  }

  componentDidMount() {
    const { _id } = this.props.data.blogpostsJson;
    getPost(_id).then(post => {
      if (!post.error) this.setState({ post });
    });
  }

  
  render() {
    const { data } = this.props;
    const { blogpostsJson } = data;
    const { post } = this.state;
    return (
    <Layout overlay page='blog'
      url='_url:blog'>
      <SEO title={blogpostsJson.metadata.title}
        lang='da-DK'
        description={blogpostsJson.metadata.description}
        meta={[{
          name: 'keywords',
          content: blogpostsJson.metadata.keyword
        }]}
        location={this.props.location}/>
      <Content post={post}/>
    </Layout>
    );
  }
}

export default Blogpost;

export const query = graphql`
  query($slug: String!) {
    blogpostsJson(fields: { slug: { eq: $slug } }) {
      metadata {
        description
        keyword
        title
        url
      }
      _id
    }
  }
`